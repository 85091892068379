@mycolor : @myRed;

@margin-md: 17px;


body{
    background-color: @darkbg;
}

.ant-menu-item:active, .ant-menu-submenu-title:active{
    background-color: @activebg;
    color: @activetext;
}

.ant-layout-sider-children{
    background-color: @menubg;
}

.sidebar-logo{
    background-color: @sidebarbg;
    border-bottom:1px solid @border
}

.item5{
    background-color: @footerbg;
}

.ant-menu{
    background-color: @menubg;
    border:0px;

    :hover{
        color: @activetext;
    }
}

.ant-menu a{
    color: @activetext;
}

.ant-menu-item:hover{
    background-color: @submenubghover;
    color: @activetext !important;


}

.ant-layout-sider , .ant-menu-inline.ant-menu-sub{
    background-color: @sidebarbg;
}

.ant-layout-header{
    background-color: @headerbg;
}


.ant-menu-submenu-selected{
    background-color: @primary-btn;
    color: @activetext;
}

.ant-layout-sider, .ant-menu-inline.ant-menu-sub{
    background-color: @submenubg;
}

.ant-menu-submenu-active{
    color: @activetext;
}


.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
    border-bottom: 1px solid @border;
}


.ant-menu-item a{
    color: @menutext;
}

.ant-menu-item a:hover{
        color: @activetext;
}

.ant-menu-submenu-title:hover{
    background-color: @primary-btn;
}

.theme-icon i{
    color: #767676;
  }


.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: @activetext;
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected{
    color: @activetext;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    color: @activetext;
}

.addwell-form{
    background-color: @add-job-panel;

    label{
        color:@form-label;
    }

    input,textarea,select{
        border:1px solid @input-border-color
    }

    input:hover,textarea:hover{
        border:1px solid @input-border-color
    }

    input:hover,input:focus,
    textarea:hover,textarea:focus{
        border:1px solid @input-border-color
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border:1px solid @input-border-color
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border:1px solid @input-border-color
    }
}


//Header Css //
.main-logo{
    color:@logo-text;
}

.blue-btn{
    background-color: @blue-btn;
}

.blue-btn:hover,
.blue-btn:active,
.blue-btn:focus{
    background-color: @blue-btn-hover;
}

.active{
    background-color: @blue-btn;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: @blue-btn;
    color: @activetext !important;
}

.ant-menu-item a{
    color: @activetext !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: @input-bg;
}
.ant-input:focus, .ant-input-focused{
    background-color: @input-bg;
}

.tablebg{
    background-color: @primary-dark;
}

.ant-btn-primary{
    background-color: @blue-btn;
}

.wellBox{
    background-color: @wellbox;
    a{
        color: @activetext;
    }
}
.well-header{
    background-color: @wellheader;
}

.announcement{
    background-color: @wellheader;
}

.ant-modal-header,.ant-modal-footer{
    background-color: @wellheader;
}



.ant-modal-body{
    background-color: @wellbox;
}

.configure-custom-btn{
    border:1px solid @border;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    background-color: @sidebarbg;
}

.ant-tabs-tab:hover, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: @activetext !important;
}

.ant-tabs-ink-bar{
    background: @primary-btn;
}

.kpi-target-header{
    background-color: @activebg;
}

.ant-radio-checked .ant-radio-inner{
    background-color: @primary-btn;
    border: 1px solid @activetext !important;
}

.ant-radio-inner::after{
    border: 1px solid @primary-btn !important;
}

.ant-collapse-header{
    background-color: @sidebarbg;
}

.ant-collapse-content{
    background-color: @headerbg;
}

.ant-checkbox-inner{
    background-color: @checkbox;
    border:1px solid @inputborder
}
.ant-checkbox-checked .ant-checkbox-inner{
    background-color: @checkbox;
    border:1px solid @inputborder
}


.ant-picker{
    background-color: @sidebarbg;
}

.action-btn a , .add-row-btn a{
    color: @activetext;
}

.ant-select{
    background-color: @sidebarbg;
}


.ant-table-cell{
    background-color: @headerbg;
}

.ant-table-thead > tr > th{
    background-color: @sidebarbg;
}

.ant-spin-lg .ant-spin-dot i{
    background-color: @primary-btn;
}

.header-button{
    .ant-btn-block{
        background-color: @blue-btn;
    }   

    
    .ant-btn:hover , .ant-btn:active, .ant-btn:focus{
        background-color:  @blue-btn-hover;
        color: #fff;
    } 

    .ant-select, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: @blue-btn;
        border: 0px !important;
     }
    
     .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
         border: 0px !important;
     }

     .ant-select:hover{
         background-color: @blue-btn-hover;
     }
     
     .ant-select-arrow{
         color:#fff
     }
}

.parameter-bg{
    background: @primary-dark;    
}

.run-properties{
    background-color: @footerbg;
    border: 1px solid @light-border;
}

.blue-bg{
    background-color: @blue-bg;
}

.ant-input:hover, .ant-input:active, .ant-input:focus{
    border:1px solid @inputborder
  }

  .rig-cost{
    .ant-table-thead > tr > th{
      background-color:@secondary-color;
    }
   }

 .chartbg{
     background-color: @chartbg;
     
 }  

 .pie-table{
    background-color: @chartbg;
 }

 .piebgcolor{
    background-color: @chartbg;
 }


 .view-options{
      .ant-select, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: @blue-btn;
 }

 .ant-select:not(.ant-select-customize-input) .ant-select-selector{
     border: 0px !important;
 }
 
 .ant-select:hover{
     background-color: @blue-btn-hover;
 }

 .ant-select-arrow{
     color:#fff
 }
}

.cancel{
    border:1px solid @input-border-color
}

.cancel:hover{
    background-color: @black-text;
    color: @white-text;
}