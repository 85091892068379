/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  background-color: #22313a;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #111b26;
  color: #ffffff;
}
.ant-layout-sider-children {
  background-color: #0d202b;
}
.sidebar-logo {
  background-color: #0d202b;
  border-bottom: 1px solid #132733;
}
.item5 {
  background-color: #1e2a31;
}
.ant-menu {
  background-color: #0d202b;
  border: 0px;
}
.ant-menu :hover {
  color: #ffffff;
}
.ant-menu a {
  color: #ffffff;
}
.ant-menu-item:hover {
  background-color: #000408;
  color: #ffffff !important;
}
.ant-layout-sider,
.ant-menu-inline.ant-menu-sub {
  background-color: #0d202b;
}
.ant-layout-header {
  background-color: #1e2a31;
}
.ant-menu-submenu-selected {
  background-color: #27a4fb;
  color: #ffffff;
}
.ant-layout-sider,
.ant-menu-inline.ant-menu-sub {
  background-color: #000c17;
}
.ant-menu-submenu-active {
  color: #ffffff;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  border-bottom: 1px solid #132733;
}
.ant-menu-item a {
  color: #9399a0;
}
.ant-menu-item a:hover {
  color: #ffffff;
}
.ant-menu-submenu-title:hover {
  background-color: #27a4fb;
}
.theme-icon i {
  color: #767676;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #ffffff;
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #ffffff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #ffffff;
}
.addwell-form {
  background-color: #0d202b;
}
.addwell-form label {
  color: #fff;
}
.addwell-form input,
.addwell-form textarea,
.addwell-form select {
  border: 1px solid #434343;
}
.addwell-form input:hover,
.addwell-form textarea:hover {
  border: 1px solid #434343;
}
.addwell-form input:hover,
.addwell-form input:focus,
.addwell-form textarea:hover,
.addwell-form textarea:focus {
  border: 1px solid #434343;
}
.addwell-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #434343;
}
.addwell-form .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #434343;
}
.main-logo {
  color: #fff;
}
.blue-btn {
  background-color: #0084ff;
}
.blue-btn:hover,
.blue-btn:active,
.blue-btn:focus {
  background-color: #44a3fc;
}
.active {
  background-color: #0084ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0084ff;
  color: #ffffff !important;
}
.ant-menu-item a {
  color: #ffffff !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0d202b;
}
.ant-input:focus,
.ant-input-focused {
  background-color: #0d202b;
}
.tablebg {
  background-color: #0d202b;
}
.ant-btn-primary {
  background-color: #0084ff;
}
.wellBox {
  background-color: #13252f;
}
.wellBox a {
  color: #ffffff;
}
.well-header {
  background-color: #0d202b;
}
.announcement {
  background-color: #0d202b;
}
.ant-modal-header,
.ant-modal-footer {
  background-color: #0d202b;
}
.ant-modal-body {
  background-color: #13252f;
}
.configure-custom-btn {
  border: 1px solid #132733;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  background-color: #0d202b;
}
.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}
.ant-tabs-ink-bar {
  background: #27a4fb;
}
.kpi-target-header {
  background-color: #111b26;
}
.ant-radio-checked .ant-radio-inner {
  background-color: #27a4fb;
  border: 1px solid #ffffff !important;
}
.ant-radio-inner::after {
  border: 1px solid #27a4fb !important;
}
.ant-collapse-header {
  background-color: #0d202b;
}
.ant-collapse-content {
  background-color: #1e2a31;
}
.ant-checkbox-inner {
  background-color: #181919;
  border: 1px solid #434343;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #181919;
  border: 1px solid #434343;
}
.ant-picker {
  background-color: #0d202b;
}
.action-btn a,
.add-row-btn a {
  color: #ffffff;
}
.ant-select {
  background-color: #0d202b;
}
.ant-table-cell {
  background-color: #1e2a31;
}
.ant-table-thead > tr > th {
  background-color: #0d202b;
}
.ant-spin-lg .ant-spin-dot i {
  background-color: #27a4fb;
}
.header-button .ant-btn-block {
  background-color: #0084ff;
}
.header-button .ant-btn:hover,
.header-button .ant-btn:active,
.header-button .ant-btn:focus {
  background-color: #44a3fc;
  color: #fff;
}
.header-button .ant-select,
.header-button .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0084ff;
  border: 0px !important;
}
.header-button .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px !important;
}
.header-button .ant-select:hover {
  background-color: #44a3fc;
}
.header-button .ant-select-arrow {
  color: #fff;
}
.parameter-bg {
  background: #0d202b;
}
.run-properties {
  background-color: #1e2a31;
  border: 1px solid #434343;
}
.blue-bg {
  background-color: #0d202b;
}
.ant-input:hover,
.ant-input:active,
.ant-input:focus {
  border: 1px solid #434343;
}
.rig-cost .ant-table-thead > tr > th {
  background-color: #1e2a31;
}
.chartbg {
  background-color: #0d202b;
}
.pie-table {
  background-color: #0d202b;
}
.piebgcolor {
  background-color: #0d202b;
}
.view-options .ant-select,
.view-options .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0084ff;
}
.view-options .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px !important;
}
.view-options .ant-select:hover {
  background-color: #44a3fc;
}
.view-options .ant-select-arrow {
  color: #fff;
}
.cancel {
  border: 1px solid #434343;
}
.cancel:hover {
  background-color: #000;
  color: #fff;
}
