
@import '../node_modules/ng-zorro-antd/ng-zorro-antd';
// @import 'styles/dark.less';
// @import 'styles/default.less';
// @import '../src/styles/themes/base.less';
@import '../src/styles/dark.less';
@import '../src/styles/default.less';

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
  
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
 
::-webkit-scrollbar-thumb {
  outline: 1px solid slategrey;
  background-color: rgb(151, 151, 151);

}

.app-header { grid-area: header; grid-template-rows: 100px}
.app-sidebar { 
  grid-area: menu;
  display: flex;
}
.router-outlet { grid-area: main; overflow-x: auto;} 
.app-footer { grid-area: footer; align-self: end; }

.grid-container {
  display: grid;
  grid-template-areas:
  'menu header header  header header header header header'
  'menu main main main main main main main'
  'menu footer footer footer footer footer footer footer';
  width: 100%;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  grid-template-columns:max-content;
}

.router-outlet{
  max-width: 100%;
}
.app-footer{
  padding: 5px 0px 0px 0px;
  text-align: center;
}

// .template-widgets-btn{
  // background-color: @ant-btn-primary;
// }


.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right:0px !important;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
  width: calc(100% + 0px);
}


.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
  margin: 0px !important;
  padding:10px 0px;
  height: auto;
}


.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  height:50px !important;
  line-height:50px  !important
}

.ant-dropdown-menu{
  padding: 0px;
}


.ant-tabs-nav-list{
  padding: 0px 15px !important;
}

.view-options{
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 42px;
    padding: 4px 25px !important;
  }
}




.overiders-layout{

  
.ant-table-pagination.ant-pagination{
  display: none;
}

.ant-tabs-tabpane{
  background: #122733;
  padding: 12px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 8px;
}
}


.ant-picker-input > input:focus{
  background-color: transparent !important;
}

.nz-table{
  width: 100%;
}




.ant-table-thead > tr > th{
  position: sticky !important;
 top:0px;
 z-index: 39;

}



.form{
  width: 100%;

  .ant-form-item-label > label{
    padding-right: 10px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed{
  display: none;
}

.ant-dropdown{
  margin-top: -10px !important;
}

.ant-dropdown-menu-item{
  color: #fff;
  a , a:hover{
    color: #fff;
  }
}

.additional-list-data{
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 2px 6px !important ;
  }
}

span.dropdown-btn {
  border: 1px solid #434343 !important;
}
.multiselect-item-checkbox input[type=checkbox] + div {color: #fff !important;
 background-color: #0f222d !important;
}

.multiselect-item-checkbox input[type=checkbox] + div::before{
  border:1px solid #434343 !important;
  background: none !important;
}

.dropdown-list{
  background: #0f222d !important;
  border: 1px solid #434343 !important;
  box-shadow: none;
}
.dropdown-list li{
 background: none !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div{
  background-color: none !important;
}

.popup .ant-modal-body{
  padding: 5px !important;
  max-height: 650px;
  overflow-y: auto;
}

.popup{
  
  .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin-bottom: 5px !important;
  }
}

.leaflet-container {
  background: #d5e8eb  !important;
}

.leaflet-pane {
  transform: translate3d(0px, 55px, 0px) ;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #0084ff;
  border-color: #0084ff;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0d202b;
  border-color: rgb(255 255 255 / 30%) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: rgb(255 255 255 / 30%) !important;
}

